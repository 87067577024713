import { VenueReservation } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type GetVenueReservationsQueryInput = {
  venuePortalUserId: string;
  reservationOffset: number;
  reservationLimit: number;
  lang: string;
  search?: {
    query?: string;
    from?: Date;
    until?: Date;
  };
};
export type GetVenueReservationsQueryResponse = {
  venuePortalUser: {
    id: string;
    pendingReservationsCount: number;

    reservations: {
      totalRecords: number;
      records: VenueReservation[];
    };
  };
};

export const reservationFields = gql`
  fragment ReservationFields on VenueReservation {
    id
    additionalInfo
    answer
    answeredBy
    created
    numGuests
    numGuides
    reservationAnswerReceived
    reservationTime

    cancellation {
      id
      created
      reason
    }

    latestInquiry {
      body
    }

    group {
      id
      date
      time
      type

      groupedGuests(lang: $lang, includeRoles: ["OJT Trainee"]) {
        name
        quantity
      }

      bookings {
        id
        details {
          id
          quantity

          productPrice {
            id
            name

            translation(lang: $lang) {
              id
              name
            }
          }
        }
      }

      guides(excludeRoles: ["OJT Trainee"]) {
        id
        name
        phoneNumber
        profilePic
      }

      guideAssignments {
        role

        guide {
          id
          name
          phoneNumber
          profilePic
        }
      }

      product {
        id
        name
      }
    }

    venue {
      id
      name
    }

    originalReservation {
      id
      answer
      numGuests
      numGuides
      reservationAnswerReceived

      snapshot {
        groupedGuests(lang: $lang, includeRoles: ["OJT Trainee"]) {
          name
          quantity
        }

        details {
          id
          quantity

          productPrice {
            id
            name

            translation(lang: $lang) {
              id
              name
            }
          }
        }

        guide {
          id
          name
          phoneNumber
          profilePic
        }
      }
    }
  }
`;

export const userFields = gql`
  fragment UserFields on VenuePortalUser {
    id
    pendingReservationsCount

    reservations(
      pendingFirst: true
      offset: $reservationOffset
      limit: $reservationLimit
      search: $search
    ) {
      totalRecords
      records {
        ...ReservationFields
      }
    }
  }
`;

export const GET_VENUE_RESERVATIONS_QUERY = gql`
  ${reservationFields}
  ${userFields}

  query GetVenuePortalUserVenueReservations(
    $venuePortalUserId: ID
    $reservationOffset: Int
    $reservationLimit: Int
    $lang: String!
    $search: VenuePortalUserVenueReservationsSearchInput
  ) {
    venuePortalUser(id: $venuePortalUserId) {
      ...UserFields
    }
  }
`;
