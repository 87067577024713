import { VenueReservation } from "@/graphql/operations";
import { gql } from "@apollo/client";
import { reservationFields } from "./GetVenueReservationsQuery";

export type GetVenueReservationQueryInput = {
  id: string;
  lang: string;
};
export type GetVenueReservationQueryResponse = {
  venueReservation: VenueReservation;
};

export const GET_VENUE_RESERVATION_QUERY = gql`
  ${reservationFields}

  query GetVenueReservation($id: ID!, $lang: String!) {
    venueReservation(id: $id) {
      ...ReservationFields
    }
  }
`;
