import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { GoogleMapsContext, latLngEquals } from "@vis.gl/react-google-maps";
import type { Ref } from "react";

export type CircleProps = google.maps.CircleOptions & {
  mapInstance: google.maps.Map;
};

export type CircleRef = Ref<google.maps.Circle | null>;

function useCircle(props: CircleProps) {
  const { radius, center, mapInstance } = props;

  const circle = useRef(
    new google.maps.Circle({ fillColor: "#007aff" })
  ).current;

  useEffect(() => {
    if (!mapInstance) {
      console.error("<mapInstance> is undefined");
      return;
    }
    circle.setMap(mapInstance);
  }, [mapInstance, circle]);

  useEffect(() => {
    if (!center) return;
    if (!latLngEquals(center, circle.getCenter())) circle.setCenter(center);
  }, [center, circle]);

  useEffect(() => {
    if (radius === undefined || radius === null) return;
    if (radius !== circle.getRadius()) circle.setRadius(radius);
  }, [radius, circle]);

  const map = useContext(GoogleMapsContext)?.map;

  // create circle instance and add to the map once the map is available
  useEffect(() => {
    if (map) circle.setMap(map);

    return () => {
      circle.setMap(null);
    };
  }, [map, circle]);

  return circle;
}

export const Circle = forwardRef((props: CircleProps, ref: CircleRef) => {
  const circle = useCircle(props);

  useImperativeHandle(ref, () => circle);

  return null;
});

Circle.displayName = "Circle";
